export const languages = [
    {
        id:1,
        name:"ReactJs"
    },
    {
        id:2,
        name:"React Native"
    },
    {
        id:3,
        name:"Flutter"
    },
    {
        id:4,
        name:"PHP"
    },
    {
        id:5,
        name:'Spring-Boot'
    },
    {
        id:6,
        name:"Java"
    },
    {
        id:7,
        name:"Python/Django"
    },
    {
        id:8,
        name:"Node Js"
    },
    {
        id:9,
        name:"HTML"
    },
    {
        id:10,
        name:"WebOs"
    },
    {
        id:11,
        name:"GIT"
    },
    {
        id:12,
        name:"MySQL"
    },
    {
        id:13,
        name:"Firebase"
    }
];